<template>
  <b-container>
    <b-row>
      <b-col
        class="d-flex justify-content-center align-items-center"
        sm="12"
        md="6"
      >
        <b-img :src="require('@/assets/images/pages/coming-soon.svg')" />
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-card>
          <b-card-title>
            Crear nuevo usuario
          </b-card-title>
          <validation-observer ref="formValidation">
            <b-form @submit.prevent="validateForm()">
              <b-form-group label="Nombre">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-input v-model="user.name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input v-model="user.email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.password"
                    autocomplete="off"
                    type="password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <!-- <b-form-group label="Rol">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-select
                    v-model="user.role_name"
                    :options="roles"
                    text-field="label"
                    value-field="key"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button type="submit">
                  {{ $t('add', { type: '' }) }}
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      loading: false,
      user: {
        name: null,
        email: null,
        role_name: 'customer',
        role_resource_id: null,
      },
      roles: [
        {
          key: 'admin',
          label: 'Administrador',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('suppliers', ['suppliers']),
    ...mapGetters('branches', ['branches']),
    ...mapGetters('auth', ['currentUser']),
  },

  created() {
    // this.fetchSuppliers()
  },

  methods: {
    ...mapActions('branches', ['fetchBranches']),
    ...mapActions('auth', ['createCustomer']),
    ...mapActions('suppliers', ['fetchSuppliers']),
    ...mapActions('users', ['createUser']),
    validateForm() {
      this.$refs.formValidation.validate().then(success => {
        if (success) {
          this.loading = true
          if (this.currentUser.role_name === 'customer') {
            this.user.role_resource_id = this.currentUser.scoped_roles[0].role_resource_id
          }
          if (this.currentUser.role_name === 'admin') {
            this.user.role_resource_id = this.$route.params.userId
          }
          this.createUser(this.user)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('typeCreated', {
                    type: this.$t('customer'),
                  }),
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .then(() => {
              this.$router.push({
                name: 'members',
                params: { userId: this.$route.params.userId },
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
